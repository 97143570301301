import { faFile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import css from './KnowledgeCentreView.module.scss'
import ScormPopup from './ScormPopup'
import { authAxios } from '../../../../helpers/axios'
import { backendUrl } from '../../../../env-config'
import { ExternalLink } from 'react-feather'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {}

const KnowledgeCentreView: React.FC<PropsI> = (props) => {
  // const cardsArr = [
  //   {
  //     title: 'Training Modules',
  //     items: [
  //       {
  //         name: 'Introduction to Product Management Module.pdf',
  //       },
  //       {
  //         name: 'Sales Techniques Workshop Video.mp4',
  //       },
  //       {
  //         name: 'Leadership Skills Assessment Form.docx',
  //       },
  //       {
  //         name: 'Cybersecurity Best Practices Guidebook.pdf',
  //       },
  //       {
  //         name: 'Excel Basics Training Slides.pptx',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Assessment Tools',
  //     items: [
  //       {
  //         name: 'Skill Proficiency Assessment',
  //       },
  //       {
  //         name: 'Leadership Competency Assessment',
  //       },
  //       {
  //         name: 'Customer Service Aptitude Test',
  //       },
  //       {
  //         name: 'Technical Skills Test',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Learning Resources',
  //     items: [
  //       {
  //         name: 'The Art of Effective Communication - E-Book',
  //       },
  //       {
  //         name: 'Data Science Essentials: A Comprehensive Guide - Whitepaper',
  //       },
  //       {
  //         name: 'Strategic Leadership in the Digital Age - Article',
  //       },
  //       {
  //         name: 'Financial Management Basics - Online Course',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Video Tutorials',
  //     items: [
  //       {
  //         name: 'Mastering Client Interaction: Effective Communication Techniques',
  //       },
  //       {
  //         name: 'Strategies for Goal Setting and Achievement',
  //       },
  //       {
  //         name: 'Navigating Challenging Conversations with Confidence',
  //       },
  //       {
  //         name: 'Time Management Mastery for Increased Productivity',
  //       },
  //       {
  //         name: 'Unlocking Leadership Potential: Key Leadership Skills',
  //       },
  //       {
  //         name: 'Introduction to Mindfulness in the Workplace',
  //       },
  //     ],
  //   },
  // ]

  const [items, setItems] = useState<any[]>([])

  useEffect(() => {
    authAxios.get(`${backendUrl}/hr/settings/get-company-resources`).then((res) => {
      setItems(res.data)
    })
  }, [])

  return (
    <div>
      <div className="flex flex-wrap gap-5 mt-5">
        {items.length <= 0 && <div className="text-gray-500">No records.</div>}
        {items.map((x) => {
          return (
            <a
              target="_blank"
              href={x.url}
              className="flex gap-2 p-4 hover:bg-primary hover:text-white transition-all cursor-pointer text-black no-underline"
              style={{
                border: '1px solid gray',
              }}
            >
              <div>
                <p className="font-bold">{x.name}</p>
                <p>{x.description || 'N/A'}</p>
                <p className="max-w-[300px] overflow-hidden whitespace-nowrap text-ellipsis">
                  {x.url}
                </p>
              </div>

              <div>
                <ExternalLink />
              </div>
            </a>
          )
        })}
      </div>
    </div>
  )
}

interface MapStatePropsI {
  // currentCompany: any
}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    // currentCompany: state.user.currentCompany,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KnowledgeCentreView))

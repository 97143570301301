import React, { useState } from 'react'
import css from './MainDashboardWrapper.module.scss'
import Collapse from '@kunukn/react-collapse'
import '@kunukn/react-collapse/dist/Collapse.umd.css'
import {
  Menu as MenuIcon,
  Grid,
  FileText,
  User,
  Users,
  CreditCard,
  ChevronDown,
  ChevronUp,
  PieChart,
  ChevronLeft,
  Settings,
  Mail,
  Activity,
  MessageCircle,
  List,
  ShoppingBag,
} from 'react-feather'
import AppLogo from '../AppLogo/AppLogo'
import { ThunkDispatch } from 'redux-thunk'
import { logout } from '../../ducks/auth.duck'
import { changeCurrentCompany } from '../../ducks/user.duck'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Avatar } from '@material-ui/core'
import { Menu, MenuItem, SubMenu } from '@szhsin/react-menu'
import { Link } from 'react-router-dom'
import { vivekaUrl } from '../../env-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {
  title?: string
  page?: string
}

const MainDashboardWrapper: React.FC<PropsI> = (props) => {
  const {
    logoutA,
    history,
    currentUser,
    currentCompany,
    changeCurrentCompanyA,
    children,
    title,
    page,
  } = props
  const currentCompanyId = currentUser?.currentCompanyId

  const [isVCorpOpen, setIsVCorpOpen] = useState(true)
  const [isVCoachOpen, setIsVCoachOpen] = useState(true)
  const [isVClientOpen, setIsVClientOpen] = useState(true)
  const [isNavOpen, setIsNavOpen] = useState(false)

  const avatarName = currentUser
    ? currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)
    : ''

  const menuItemStyles = {
    hover: {
      backgroundColor: css.primaryColor,
      color: 'white',
    },
    active: {
      backgroundColor: css.primaryColor,
      color: 'white',
    },
  }

  const roles: string[] = currentCompany?.UserCompanyModel.roles

  const isProvider = currentUser?.publicData?.isProvider

  // const currentCompany = currentUser?.companies?.find((x: any) => x.id === currentCompanyId)
  // console.log({ currentCompany })

  return (
    <div className={css.base}>
      <div className={`${css.menu_base} ${isNavOpen && css.menu_open}`}>
        <div className={css.menu_nav_top}>
          <AppLogo
            className={css.logo}
            customUrl={currentCompany?.logoUrl && currentCompany.logoUrl}
            style={{
              filter: currentCompany?.logoUrl ? 'none' : 'grayscale(100%)',
            }}
          />
          <ChevronLeft className={css.menu_close_button} onClick={() => setIsNavOpen(false)} />
        </div>

        <div className={css.section_wrapper}>
          {roles?.includes('hr') && (
            <div className={css.menu_section}>
              <div className={css.menu_item_wrapper} onClick={() => setIsVCorpOpen(!isVCorpOpen)}>
                <p>V-Corp</p>
                {isVCorpOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
              <Collapse isOpen={isVCorpOpen}>
                <Link
                  to="/hr/dashboard/monthly"
                  className={`${css.collapse_item_wrapper} ${
                    page === 'corp-dashboard' ? css.menu_item_active : ''
                  }`}
                >
                  <Grid />
                  <p>Dashboard</p>
                </Link>
                <Link
                  to="/hr/curriculum"
                  className={`${css.collapse_item_wrapper} ${
                    page === 'corp-curriculum' ? css.menu_item_active : ''
                  }`}
                >
                  <FileText />

                  <p>Curriculum</p>
                </Link>

                <Link
                  to="/hr/survey"
                  className={`${css.collapse_item_wrapper} ${
                    page === 'corp-survey' ? css.menu_item_active : ''
                  }`}
                >
                  <List />
                  <p>Surveys</p>
                </Link>

                <Link
                  to="/hr/coach"
                  className={`${css.collapse_item_wrapper} ${
                    page === 'corp-coach' ? css.menu_item_active : ''
                  }`}
                >
                  <User />
                  <p>Coach</p>
                </Link>

                <Link
                  to="/hr/employee"
                  className={`${css.collapse_item_wrapper} ${
                    page === 'corp-employees' ? css.menu_item_active : ''
                  }`}
                >
                  <Users />
                  <p>Employees</p>
                </Link>

                <Link
                  to="/hr/billing/budget"
                  className={`${css.collapse_item_wrapper} ${
                    page === 'corp-billing' ? css.menu_item_active : ''
                  }`}
                >
                  <CreditCard />
                  <p>Billing</p>
                </Link>

                <Link
                  to="/hr/settings"
                  className={`${css.collapse_item_wrapper} ${
                    page === 'corp-settings' ? css.menu_item_active : ''
                  }`}
                >
                  <Settings />
                  <p>Settings</p>
                </Link>
              </Collapse>
            </div>
          )}

          {isProvider && (
            <div className={css.menu_section}>
              <div className={css.menu_item_wrapper} onClick={() => setIsVCoachOpen(!isVCoachOpen)}>
                <p>V-Coach</p>
                {isVCoachOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
              <Collapse isOpen={isVCoachOpen}>
                <a
                  href={`${vivekaUrl}/v-coach/clients-activities`}
                  className={css.collapse_item_wrapper}
                >
                  <Grid />
                  <p>Dashboard</p>
                </a>
                <a
                  href={`${vivekaUrl}/v-coach/programs`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-program' ? css.menu_item_active : ''
                  }`}
                >
                  <FileText />
                  <p>Programs</p>
                </a>
                <a
                  href={`${vivekaUrl}/listings`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-ads' ? css.menu_item_active : ''
                  }`}
                >
                  <Activity />
                  <p>Ads</p>
                </a>

                <a
                  href={`${vivekaUrl}/saas-chats/coach`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-messages' ? css.menu_item_active : ''
                  }`}
                >
                  <Mail />
                  <p>Messages</p>
                </a>

                <a
                  href={`${vivekaUrl}/referral_program`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-referral' ? css.menu_item_active : ''
                  }`}
                >
                  <Users />
                  <p>Referrals</p>
                </a>
              </Collapse>
            </div>
          )}

          {roles?.includes('employee') && (
            <div className={css.menu_section}>
              <div
                className={css.menu_item_wrapper}
                onClick={() => setIsVClientOpen(!isVClientOpen)}
              >
                <p>V-Client</p>
                {isVClientOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
              <Collapse isOpen={isVClientOpen}>
                {/*<div className={css.collapse_item_wrapper}>*/}
                {/*  <FileText />*/}
                {/*  <p>Corp Inc</p>*/}
                {/*</div>*/}

                <Link
                  to="/employee/company"
                  className={`${css.collapse_item_wrapper} ${
                    page === 'client-company' ? css.menu_item_active : ''
                  }`}
                >
                  <FileText />
                  <p>{currentCompany?.name ? currentCompany?.name : 'Corp Inc'}</p>
                </Link>

                <a
                  href={`${vivekaUrl}/v-coach/client/dashboard`}
                  className={css.collapse_item_wrapper}
                >
                  <Grid />
                  <p>Dashboard</p>
                </a>

                {/* <a
                  href={`${vivekaUrl}/v-coach/client/my-activities`}
                  className={css.collapse_item_wrapper}
                >
                  <PieChart />
                  <p>My Activities</p>
                </a> */}

                <a href={`${vivekaUrl}/saas-chats/client`} className={css.collapse_item_wrapper}>
                  <Mail />
                  <p>Messages</p>
                </a>

                <a
                  href={`${vivekaUrl}/s`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'client-marketplace' ? css.menu_item_active : ''
                  }`}
                >
                  <ShoppingBag />
                  <p className=" text-ellipsis overflow-hidden whitespace-nowrap">Find A Coach</p>
                </a>

                {/*<div className={css.collapse_item_wrapper}>*/}
                {/*  <Mail />*/}
                {/*  <p>Messages</p>*/}
                {/*</div>*/}
              </Collapse>
            </div>
          )}

          <hr />
          <div className={css.menu_section}>
            <a
              href={`${vivekaUrl}/inbox/sales`}
              className={`${css.collapse_item_wrapper} ${
                page === 'all-inquiry' ? css.menu_item_active : ''
              }`}
            >
              <MessageCircle />
              <p>Inquiries</p>
            </a>

            <a
              href={`${vivekaUrl}/v-coach/settings`}
              className={`${css.collapse_item_wrapper} ${
                page === 'all-setting' ? css.menu_item_active : ''
              }`}
            >
              <Settings />
              <p>User settings</p>
            </a>
          </div>
        </div>
      </div>
      <div className={css.content_base}>
        <div className={css.nav_bar}>
          <MenuIcon onClick={() => setIsNavOpen(true)} />
          <p className={css.nav_title}>{title}</p>
          <div className={css.nav_actions}>
            {/*<Bell />*/}
            <Menu
              direction="left"
              menuButton={
                <div className={css.avatar_wrapper}>
                  <Avatar src={currentUser?.profilePic}>{avatarName}</Avatar>
                  <div>
                    <p>
                      {currentUser?.displayName}{' '}
                      {/*<span style={{ color: 'gray', fontWeight: 300 }}>({currentUser?.email})</span>*/}
                    </p>
                    <p>{currentCompany?.name}</p>
                  </div>
                </div>
              }
            >
              {currentUser?.companies && (
                <SubMenu label="Switch Company">
                  {currentUser.companies.map((item: any, index: number) => {
                    return (
                      <MenuItem
                        key={index}
                        disabled={currentCompanyId === item.id}
                        styles={menuItemStyles}
                        onClick={() => {
                          changeCurrentCompanyA(item.id)
                          return true
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    )
                  })}
                  {/*<MenuItem*/}
                  {/*  styles={{ color: css.primaryColor, ...menuItemStyles }}*/}
                  {/*  onClick={() => {*/}
                  {/*    history.push('/my-companies/create')*/}
                  {/*    return true*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Create a Company*/}
                  {/*</MenuItem>*/}
                </SubMenu>
              )}

              <MenuItem
                styles={menuItemStyles}
                onClick={() => {
                  window.open(`${vivekaUrl}/profile-settings`, '_self')
                  return true
                }}
              >
                My Profile
              </MenuItem>

              <MenuItem
                styles={menuItemStyles}
                onClick={() => {
                  logoutA().then(() => history.push('/auth/login'))
                  return true
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className={css.content}>{children}</div>
      </div>
    </div>
  )
}
interface MapStatePropsI {
  currentUser: any
  currentCompany: any
}

interface MapDispatchPropsI {
  logoutA: () => any
  changeCurrentCompanyA: (companyId: number) => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    currentUser: state.user.currentUser,
    currentCompany: state.user.currentCompany,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {
    logoutA: () => dispatch(logout()),
    changeCurrentCompanyA: (companyId: number) => dispatch(changeCurrentCompany(companyId)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainDashboardWrapper))

import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Popup from 'reactjs-popup'
// import 'reactjs-popup/dist/index.css'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@material-ui/core'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { TextField } from 'formik-material-ui'
import { useConfirm } from 'material-ui-confirm'
import { useAlert } from 'react-alert'
import * as yup from 'yup'
import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

interface FormValuesI {
  name: string
  url: string
  description: string
}

const validationSchema = yup.object({
  name: yup.string().required(),
  url: yup.string().required().url(),
  description: yup.string(),
})

interface PropsI extends RouteComponentProps {
  trigger: JSX.Element | ((isOpen: boolean) => JSX.Element)
  cb: () => void
}

const AddLinkPopup: React.FC<PropsI> = (props) => {
  const { trigger, cb } = props
  // const [name, setName] = useState('')
  // const [url, seturl] = useState('')

  const confirm = useConfirm()
  const alert = useAlert()

  return (
    <Popup trigger={trigger} modal closeOnDocumentClick={false}>
      {(close: any) => (
        <div className="relative p-5">
          <FontAwesomeIcon
            icon={faTimes}
            className="absolute top-0 right-0 text-red-400 cursor-pointer"
            onClick={close}
          />

          {/* <div className="flex flex-col gap-3">
            <TextField
              onChange={(e) => setName(e.target.value)}
              value={name}
              label="Name"
              variant="outlined"
            />
            <TextField
              onChange={(e) => seturl(e.target.value)}
              value={url}
              label="Url"
              variant="outlined"
            />
            <TextField
              onChange={(e) => seturl(e.target.value)}
              value={url}
              label="Url"
              variant="outlined"
            />

            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              disableElevation
              className="w-fit"
              onClick={() => {
                console.log({
                  name,
                  url,
                })
              }}
            >
              Add
            </Button>
          </div> */}

          <Formik
            initialValues={{
              name: '',
              url: '',
              description: '',
            }}
            onSubmit={(values: any, formikHelpers: FormikHelpers<FormValuesI>) => {
              console.log(values)
              authAxios
                .post(`${backendUrl}/hr/settings/add-company-resources`, values)
                .then((res) => {
                  console.log(res.data)
                  formikHelpers.setSubmitting(false)
                  cb()
                  close()
                })
            }}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {(formikProps: FormikProps<FormValuesI>) => {
              return (
                <Form className="flex flex-col gap-3">
                  <Field
                    component={TextField}
                    name="name"
                    variant="outlined"
                    label="Name"
                    color="primary"
                  />

                  <Field
                    component={TextField}
                    name="url"
                    variant="outlined"
                    label="Url"
                    color="primary"
                  />

                  <Field
                    component={TextField}
                    name="description"
                    variant="outlined"
                    label="Description"
                    color="primary"
                  />

                  <Button
                    // onClick={() => {
                    //   formikProps.submitForm()
                    // }}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disableElevation
                    disabled={
                      formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty
                    }
                    className="w-fit"
                  >
                    Add
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      )}
    </Popup>
  )
}

export default withRouter(AddLinkPopup)
